




























































































































































import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { SavingsServiceType } from '@/vue-app/types';
import { getScreenSizeVariant } from '@/vue-app/utils/screen';
import ContractSavingsFinancialInformationViewModel
  from '@/vue-app/view-models/components/contract-savings/financial-information/contract-savings-financial-information-view-model';

@Component({
  name: 'ContractSavingsFinancialInformation',
  components: {
    ContractSavingsActions: () => import('@/vue-app/components/contract-savings/ContractSavingsActions.vue'),
  },
})
export default class ContractSavingsFinancialInformation extends Vue {
  @Prop(String)
  readonly service!: SavingsServiceType;

  financial_information_view_model = Vue.observable(
    new ContractSavingsFinancialInformationViewModel(),
  );

  @Watch('financial_information_view_model.is_loading')
  onLoadChange(is_loading: boolean) {
    this.$emit('loadingInfo', is_loading);
  }

  @Watch('financial_information_view_model.form_validity')
  async onValidFormChange(data: boolean) {
    if (data) {
      await this.financial_information_view_model.loadFinancialInstitutionsByService(this.service);
    }
  }

  @Watch('financial_information_view_model.account_status')
  onFileChange(file: File) {
    this.financial_information_view_model.setFileData(file);
  }

  prevStep() {
    this.$emit('prevStep');
  }

  async nextStep() {
    const save_step = await this.financial_information_view_model.saveStep();
    if (save_step) {
      this.$emit('nextStep');
    }
  }

  async created() {
    await this.financial_information_view_model.initialize(getScreenSizeVariant(), this.service);
    this.$emit('loadingInfo', this.financial_information_view_model.is_loading);
  }
}

